<template>
    <div class="component-car-park">
        <div class="inner-wrapper">

            <h4 class="title">{{ $t('carPark.title') }}</h4>

            <div class="cars-list">
                <div
                    class="car"
                    v-for="(item, key) in cars" :key="key"
                    @click="selectCar(key)"
                >
                    <div class="car-preview-wrapper">
                        <img class="car-preview" :src="item.preview" alt="car" />
                        <span class="overlay">{{ $t('carPark.seeInterior') }}</span>
                    </div>
                </div>
            </div>

            <div class="car-interior-container" v-if="selectedCar">
                <div class="car-interior-wrapper">
                    <div
                        class="car-interior-images"
                        v-for="(image, index) in interiorImages" :key="index"
                    >
                        <img class="interior-image" :src="image" alt=" " />
                    </div>
                </div>

                <div class="car-interior-desc">
                    <p
                        class="description"
                        v-for="(desc, index) in descriptionKeys" :key="index"
                        v-html="$t(desc)"
                    ></p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import car_class_e from "../../../assets/cars/car_class_e.png"
import e_interior_1 from "../../../assets/cars/e/interior_1.png"
import e_interior_2 from "../../../assets/cars/e/interior_2.png"
import e_interior_3 from "../../../assets/cars/e/interior_3.png"

import car_class_c from "../../../assets/cars/car_class_s.png"
import car_class_v from "../../../assets/cars/car_class_v.png"
import car_class_van from "../../../assets/cars/car_class_van.png"

export default {
    name: "CarPark",
    data() {
        return {
            cars: {
                car_class_e: {
                    preview: car_class_e,
                },
                car_class_c: {
                    preview: car_class_c,
                },
                car_class_v: {
                    preview: car_class_v,
                },
                car_class_van: {
                    preview: car_class_van,
                },
            },
            interiorImages: [
                e_interior_1,
                e_interior_2,
                e_interior_3,
            ],
            descriptionKeys: [
                'carPark.class_e.desc1',
                'carPark.class_e.desc2',
            ],

            selectedCar: null
        }
    },
    methods: {
        selectCar(carType) {
            this.selectedCar = carType
        }
    },
}
</script>

<style lang="scss" scoped>
.component-car-park::v-deep(.inner-wrapper) {
    margin-bottom: 10px;

    h4 {
        margin-top: 20px;
        margin-bottom: 25px;
        text-align: center;
    }

    .cars-list {
        display: flex;
        gap: 30px;

        .car {
            width: calc(100%/4 - 30px/3);

            .car-preview-wrapper {
                position: relative;
                width: 100%;

                .car-preview {
                    width: 100%;
                }

                .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    cursor: pointer;

                    left: 0;
                    display: -webkit-box;
                    display: flex;
                    -webkit-box-align: center;
                    -webkit-box-pack: center;
                    -webkit-transition: .5s ease-in-out;
                    transition: all .5s ease-in-out;
                    z-index: 1;
                    opacity: 0;
                    background-color: #151111ab;
                    border-radius: 5px;
                    color: #fff;
                    outline: 3px solid $primary;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .car-interior-wrapper {
        display: flex;
        gap: 20px;

        .car-interior-images {
            flex-basis: calc(100%/3 - 20px/2);

            .interior-image {
                width: 100%;
                margin: 20px 0;
            }
        }
    }

    .car-interior-desc {
        .description {
            margin: 5px 0;
            font-size: 15px;

            .text-bold {
                font-weight: 600;
            }
        }
    }
}
</style>