<template>
    <b-dropdown id="dropdown-1" :text="selectedLanguage" class="lang-switcher">
        <b-dropdown-item @click="setLang('sk')">SK</b-dropdown-item>
        <b-dropdown-item @click="setLang('en')">EN</b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    name: "LangSwitcher",
    data() {
        return {
            lang: 'SK'
        }
    },
    methods: {
        setLang(lang) {
            this.$i18n.locale = lang
        }
    },
    computed: {
        selectedLanguage() {
            return this.$i18n.locale.toUpperCase()
        }
    }
}
</script>

<style lang="scss" scoped>
.lang-switcher::v-deep {
    .dropdown-toggle {
        margin: 0;
        padding: 1px 6px;
        min-height: unset;
        font-size: 14px;
        font-weight: 600;
        border: 0;
        background: transparent;

        &:focus {
            box-shadow: none;
        }

        &:after {
            //display: none;
        }
    }

    .dropdown-menu {
        min-width: unset;
        padding: 0;

        .dropdown-item {
            font-size: 14px;
            font-weight: 600;
            color: $bg-menu;

            &:hover {
                color: $primary;
                background: transparent;
            }
        }
    }
}
</style>