<template>
    <div class="component-never-pay-item">
        <div class="number">{{ number }}</div>
        <div class="text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: "NeverPayItem",
    props: {
        number: {
            type: String,
        },
        text: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.component-never-pay-item::v-deep {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .number, .text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .number {
        margin-right: 10px;
        color: $primary;
        font-size: 14px;
        font-weight: 600;
    }

    .text {
        font-weight: 600;
        color: $heading-gray;
    }
}
</style>