<template>
    <b-navbar toggleable="lg" class="component-main-menu">
        <div class="main-menu-wrapper container">

            <b-navbar-brand href="#">
                <div class="logo-wrapper">
                    <img class="logo-img" :src="logo" alt="logo" />
                </div>
<!--                <div class="contact-info">-->
<!--                    <div class="contact-wrapper email">-->
<!--                        <b-icon class="icon" icon="envelope"></b-icon>-->
<!--                        <a href="mailto:info@transferservice.sk" class="allow-copy">info@transferservice.sk</a>-->
<!--                    </div>-->
<!--                    <div class="contact-wrapper phone">-->
<!--                        <b-icon class="icon" icon="telephone"></b-icon>-->
<!--                        <a href="tel:+421942423234" class="allow-copy">+421 942 423 234</a>-->
<!--                    </div>-->
<!--                </div>-->
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse">
                <b-icon class="menu-icon" icon="list"></b-icon>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <div class="lang-switcher-wrapper">
                    <LangSwitcher />
                </div>

                <div class="top-menu-list">
<!--                    <div class="list-item" v-b-toggle.collapse-1>-->
<!--                        <a href="#">Stav Objednavky</a>-->
<!--                    </div>-->

                    <div
                        class="list-item"
                        v-for="(item, index) in topMenuItems" :key="index"
                        @click="redirectToPage(item)"
                    >
                        <a class="list-item-link" href="#">
                            {{ $t('menuItems.' + item.tKey) }}
                        </a>
                    </div>
                </div>

                <div class="main-menu-list">
                    <div
                        class="list-item"
                        v-for="(item, index) in mainMenuItems" :key="index"
                        @click="redirectTo(item)"
                    >
                        <a class="list-item-link" href="#">{{ $t('menuItems.' + item.tKey) }}</a>
                    </div>
                </div>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import logo from "../../assets/logo_transferservice.svg";
import LangSwitcher from "./LangSwitcher";

export default {
    name: "MainMenu",
    components: {
        LangSwitcher
    },
    data() {
        return {
            logo: logo,

            topMenuItems: [
                { tKey: 'swiss', redirectPage: '', redirectUrl: 'https://www.swissre.com/' },
                { tKey: 'registration', redirectPage: 'PageRegistration' },
                { tKey: 'login', redirectPage: '', redirectUrl: this.$config.BASE_URL },
            ],
            mainMenuItems: [
                { tKey: 'introduction', redirectPage: 'Home' },
                { tKey: 'services', redirectPage: 'Home'},
                { tKey: 'aboutUs', redirectPage: 'PageAboutUs' },
                { tKey: 'contact', redirectPage: '' },
                { tKey: 'carRental', redirectPage: '' },
            ]
        }
    },
    methods: {
        redirectToPage(page) {
            // console.log('redirectToPage', page)
            if (page.redirectPage) {
                this.$router.push({ name: page.redirectPage }).catch(() => {})
            }

            if (page.redirectUrl) {
                window.open(page.redirectUrl, "_blank")
            }
        },
        redirectTo(page) {
            if (page.tKey === 'services') {
                this.$router.push({ name: page.redirectPage, query: { scrollRef: 'ref_services' } }).catch(() => {})
            } else {
                this.$router.push({ name: page.redirectPage }).catch(() => {})
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.component-main-menu::v-deep {
    padding: 10px 0;
    background: $bg-menu;

    // menu btn
    .navbar-toggler {
        background: transparent;
        padding: 0;

        .menu-icon {
            color: $primary;
            font-size: 30px;
        }
    }

    .contact-info {
        margin-top: 10px;

        .contact-wrapper {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 7px;
            }

            .allow-copy {
                color: white;
            }
        }
    }

    .top-menu-list, .main-menu-list {
        display: flex;
        flex-direction: row;
        margin: 5px 0;

        .list-item {
            position: relative;

            .list-item-link {
                color: white;
                font-size: 14px;
            }
        }
    }

    .top-menu-list {
        .list-item {
            &:not(:last-child) {
                margin-right: 10px;
                padding-right: 10px;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    height: 14px;
                    width: 1px;
                    background: white;
                }
            }
        }
    }

    .main-menu-list {
        .list-item {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-menu-wrapper {
            .navbar-collapse {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

    @media (max-width: 991px) {
        .logo-wrapper .logo-img {
            max-width: 100px;
        }

        .lang-switcher-wrapper {
            display: flex;
            align-content: center;
            justify-content: flex-end;
            margin-top: 10px;

            .lang-switcher {
                .dropdown-toggle {
                    padding-right: 0;
                }

                .dropdown-menu {
                    left: auto;
                    right: 0;
                }
            }
        }

        .top-menu-list, .main-menu-list {
            flex-direction: column;

            .list-item {
                padding: 5px 0;
                text-align: end;
                width: 100%;
            }
        }

        .top-menu-list {
            .list-item {
                border-bottom: 1px solid #3d3d3d;

                &:after {
                    display: none;
                }

                &:not(:last-child) {
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }

        .main-menu-list {
            .list-item {
                &:not(:last-child) {
                    margin-right: 0;
                    border-bottom: 1px solid #3d3d3d;
                }
            }
        }
    }

    @media (max-width: 576px) {
        padding: 10px 20px !important;
    }
}
</style>