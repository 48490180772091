import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
    state: {
        appSettings: {},
    },
    getters: {
        getAppSettings: state => {
            return state.appSettings
        },
        getAppSettingsByKey: state => key => {
            return state.appSettings[key]
        },
    },
    mutations: {
        setAppSettings(state, data) {
            state.appSettings = data
        },
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        createPersistedState()
    ],
})
