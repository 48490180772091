<template>
	<div class="component-the-services container">
        <h2 class="title">{{ $t(services.title) }}</h2>

        <div class="services-list-wrapper">
            <div
                class="services-list-item"
                v-for="(item, index) in services" :key="index"
            >
                <div class="image-wrapper">
                    <img class="image" :src="item.image" alt="sluzby">
                </div>
                <div class="text-wrapper">
                    <h3 class="title">{{ $t(item.tKey.title) }}</h3>
                    <div class="description">{{ $t(item.tKey.desc) }}</div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			services: [
				{
					image: 'https://transferservice.amcef.sk/assets/photos/sluzby4.png',
					tKey: {
                        title: 'services.company.title',
                        desc: 'services.company.desc'
                    }
				},
				{
					image: 'https://transferservice.amcef.sk/assets/photos/sluzby1.png',
					tKey: {
                        title: 'services.taxi.title',
                        desc: 'services.taxi.desc'
                    }
				},
				{
					image: 'https://transferservice.amcef.sk/assets/photos/sluzby2.png',
					tKey: {
                        title: 'services.minibus.title',
                        desc: 'services.minibus.desc'
                    }
				},
                {
                    image: 'https://transferservice.amcef.sk/assets/photos/sluzby3.png',
                    tKey: {
                        title: 'services.carRental.title',
                        desc: 'services.carRental.desc'
                    }
                }
			]
		}
	},
}
</script>

<style lang="scss" scoped>
.component-the-services::v-deep {
	padding-top: 20px;
	padding-bottom: 40px;
	
	.title {
		margin-bottom: 20px;
	}

	.services-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

		.services-list-item {
            //flex: 0 1 calc(calc(100% / 4) - 20px);
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: calc(calc(100% / 4) - 20px);

            display: flex;
            flex-direction: column;
            margin: 20px 0;

            .image-wrapper {
                margin-bottom: 15px;
                border-radius: 5px 5px 0 0;
                overflow: hidden;

                .image {
					width: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
				}
			}
			
			.text-wrapper {
                flex-grow: 1;

                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 20px;
                background: $bg-dark;
                border-radius: 0 0 5px 5px;

				.title {
					color: $gray;
					font-size: 18px;
					font-weight: 600;
                    text-align: center;
				}

				.description {
					color: $gray;
					font-size: 16px;
					font-weight: 500;
                    text-align: center;
				}
			}

            @media (max-width: 992px) {
                flex-basis: calc(calc(100% / 2) - 40px);
            }

            @media (max-width: 768px) {
                flex-basis: calc(100% - 40px);
            }
		}

        @media (max-width: 768px) {
            justify-content: center;
        }
	}
}
</style>