<template>
	<div class="component-banner">
        <div class="banner-image">
            <img src="../../assets/header_banner.png" alt="banner">
        </div>

        <div class="banner-text container">
            <h1 class="banner-title" v-html="$t('homeBannerTitle')"></h1>
        </div>

<!--        <swiper-->
<!--            class="swiper-wrapper"-->
<!--            :spaceBetween="0"-->
<!--            :slidesPerView="1"-->
<!--            :loop="true"-->
<!--            :observer="true"-->
<!--            :observeParents="true"-->
<!--            :observeSlideChildren="true"-->
<!--            :pagination="{-->
<!--                clickable: true-->
<!--            }"-->
<!--            :autoplay="{-->
<!--                delay: 5000,-->
<!--                disableOnInteraction: false-->
<!--            }"-->
<!--            effect="fade"-->
<!--        >-->
<!--            <swiper-slide-->
<!--                class="swiper-slide"-->
<!--                v-for="(img, index) in images" :key="index"-->
<!--            >-->
<!--                <img class="banner-image" :src="img" alt=" " />-->
<!--            </swiper-slide>-->
<!--        </swiper>-->
	</div>
</template>

<script>
import image1 from "../../assets/banner/1.jpg"
import image2 from "../../assets/banner/2.jpg"
import image3 from "../../assets/banner/3.jpg"
import image4 from "../../assets/banner/4.jpg"
import image5 from "../../assets/banner/5.jpg"
import image6 from "../../assets/banner/6.jpg"

// import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// import { EffectFade, Pagination, Autoplay } from 'swiper';
// SwiperCore.use([EffectFade, Pagination, Autoplay])
// import 'swiper/swiper-bundle.css'
// import 'swiper/components/effect-fade/effect-fade.scss';

export default {
    components: {
        // Swiper,
        // SwiperSlide
    },
    data() {
        return {
            images: [ image1, image2, image3, image4, image5, image6 ],
        }
    },
}
</script>

<style lang="scss" scoped>
.component-banner::v-deep {

    position: relative;
    //height: 400px;
    width: 100%;
    //aspect-ratio: 4.8;
    overflow: hidden;

    border-bottom: 4px solid $primary;

    .banner-image {
        position: absolute;
        width: 100%;
        height: 400px;

        img {
            min-width: 1500px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 0 40%;
        }
    }

    .banner-text {
        position: relative;
        z-index: 2;
        margin-top: 200px;
        margin-bottom: 60px;

        .banner-title {
            color: white;
            line-height: 1;
            margin-bottom: 10px;

            &-part {
                font-size: 32px;
                font-weight: 400;
            }
        }
    }

    @media (max-width: 768px) {
        .banner-text {
            margin-bottom: 0;

            .banner-title {
                font-size: 28px !important;

                &-part {
                    font-size: 19px;
                }
            }
        }
    }


    // SWIPER JS
	//position: relative;
	//width: 100%;
	//border-bottom: 4px solid $primary;
    //
    //.swiper-wrapper {
    //    background: $bg-menu;
    //
    //    .swiper-slide {
    //        display: flex;
    //        justify-content: center;
    //
    //        .banner-image {
    //            width: 100%;
    //            bottom: 0;
    //            max-width: 1100px;
    //            min-height: 200px;
    //            object-fit: cover;
    //            cursor: pointer;
    //        }
    //    }
    //
    //    .swiper-pagination {
    //        .swiper-pagination-bullet {
    //            background: white;
    //            margin: 0 15px;
    //            cursor: pointer;
    //
    //            &-active {
    //                background: $primary;
    //            }
    //        }
    //
    //        @media (max-width: 800px) {
    //            bottom: 5px;
    //        }
    //    }
    //}
}
</style>