<template>
    <div class="page page-home">
        <Banner />

        <div class="home-tabs-wrapper">
            <div class="container">
                <HomeTabs />

                <TabsBenefits />
            </div>
        </div>

        <div ref="ref_services">
            <TheServices />
        </div>
        <div ref="ref_benefits">
            <TheBenefits />
        </div>

        <div ref="ref_never_pay">
            <NeverPay />
        </div>
    </div>
</template>

<script>
import Banner from "../components/homepage/Banner";
import HomeTabs from "../components/homepage/HomeTabs";
import TabsBenefits from "../components/homepage/TabsBenefits";
import TheServices from "../components/homepage/TheServices";
import TheBenefits from "../components/homepage/TheBenefits";
import NeverPay from "../components/homepage/NeverPay";

export default {
    name: 'Home',
    components: {
        NeverPay,
        TheBenefits,
        TheServices,
        TabsBenefits,
        HomeTabs,
        Banner,
    },
    mounted() {
        if (this.$route.query.scrollRef) {
            this.handleClick(this.$route.query.scrollRef)
            this.$router.replace('/')
        }
    },
    watch: {
        '$route.query.scrollRef': {
            handler() {
                if (this.$route.query.scrollRef) {
                    this.handleClick(this.$route.query.scrollRef)
                    this.$router.replace('/')
                }
            },
            deep: true
        }
    },
    methods: {
        handleClick(scrollRef) {
            setTimeout(() => {
                this.$refs[scrollRef].scrollIntoView({ behavior: 'smooth' });
            }, 500)
        }
    },
}
</script>

<style lang="scss" scoped>
.page-home::v-deep {
    .home-tabs-wrapper {
        background: #fafafa;
        padding-bottom: 30px;
    }
}
</style>
