<template>
    <div class="page page-registration">
        <Banner />

        <div class="container">

            <h1 class="registration-title">{{ $t('registration') }}</h1>

            <div class="registration-form">

                <div class="flex-row flex-row--3">
                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('first_name') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.first_name"
                                type="text"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.first_name.$error">
                            <div class="error-text" v-if="!$v.formData.first_name.required">{{ $t('vForm.first_name.required') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.first_name && errors.first_name[0]">
                            <div class="error-text">{{ errors.first_name[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('last_name') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.last_name"
                                type="text"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.last_name.$error">
                            <div class="error-text" v-if="!$v.formData.last_name.required">{{ $t('vForm.last_name.required') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.last_name && errors.last_name[0]">
                            <div class="error-text">{{ errors.last_name[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('email') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.email"
                                type="email"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.email.$error">
                            <div class="error-text" v-if="!$v.formData.email.required">{{ $t('vForm.email.required') }}</div>
                            <div class="error-text" v-if="!$v.formData.email.email">{{ $t('vForm.email.email') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.email && errors.email[0]">
                            <div class="error-text">{{ errors.email[0] }}</div>
                        </div>
                    </div>
                </div>

                <div class="flex-row flex-row--3">
                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('phone') }}:</div>
                            <vue-tel-input
                                v-model="formData.phone"
                                :inputOptions="{
                                    showDialCode: true
                                }"
                            ></vue-tel-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.phone.$error">
                            <div class="error-text" v-if="!$v.formData.phone.required">{{ $t('vForm.phone.required') }}</div>
                            <div class="error-text" v-if="!$v.formData.phone.minLength">{{ $t('vForm.phone.minLength') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.phone && errors.phone[0]">
                            <div class="error-text">{{ errors.phone[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('password') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.password"
                                type="password"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.password.$error">
                            <div class="error-text" v-if="!$v.formData.password.required">{{ $t('vForm.password.required') }}</div>
                            <div class="error-text" v-if="!$v.formData.password.minLength">{{ $t('vForm.password.minLength') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.password && errors.password[0]">
                            <div class="error-text">{{ errors.password[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('password_confirmation') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.password_confirmation"
                                type="password"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.password_confirmation.$error">
                            <div class="error-text" v-if="!$v.formData.password_confirmation.required">{{ $t('vForm.password_confirmation.required') }}</div>
                            <div class="error-text" v-if="!$v.formData.password_confirmation.minLength">{{ $t('vForm.password_confirmation.minLength') }}</div>
                            <div class="error-text" v-if="!$v.formData.password_confirmation.sameAs">{{ $t('vForm.password_confirmation.sameAs') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.password_confirmation && errors.password_confirmation[0]">
                            <div class="error-text">{{ errors.password_confirmation[0] }}</div>
                        </div>
                    </div>
                </div>

                <div class="flex-row flex-row--4">
                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('street') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.street"
                                type="text"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.street.$error">
                            <div class="error-text" v-if="!$v.formData.street.required">{{ $t('vForm.street.required') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.street && errors.street[0]">
                            <div class="error-text">{{ errors.street[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('zip_code') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.zip_code"
                                type="number"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.zip_code.$error">
                            <div class="error-text" v-if="!$v.formData.zip_code.required">{{ $t('vForm.zip_code.required') }}</div>
                            <div class="error-text" v-if="!$v.formData.zip_code.minLength || !$v.formData.zip_code.maxLength">{{ $t('vForm.zip_code.minLength') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.zip_code && errors.zip_code[0]">
                            <div class="error-text">{{ errors.zip_code[0] }}</div>
                        </div>
                    </div>

                    <div class="input-container">
                        <div class="input-wrapper">
                            <div class="input-label">{{ $t('city') }}:</div>
                            <b-form-input
                                class="input"
                                v-model="formData.city"
                                type="text"
                            ></b-form-input>
                        </div>
                        <div class="error-text-wrapper" v-if="$v.formData.city.$error">
                            <div class="error-text" v-if="!$v.formData.city.required">{{ $t('vForm.city.required') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.city && errors.city[0]">
                            <div class="error-text">{{ errors.city[0] }}</div>
                        </div>
                    </div>

                    <div class="select-country-wrapper">
                        <b-form-select
                            placeholder="test"
                            v-model="formData.country_id"
                            :options="countriesOptions"
                        ></b-form-select>
                        <div class="error-text-wrapper" v-if="$v.formData.country_id.$error">
                            <div class="error-text" v-if="!$v.formData.country_id.required">{{ $t('vForm.country_id.required') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.country_id && errors.country_id[0]">
                            <div class="error-text">{{ errors.country_id[0] }}</div>
                        </div>
                    </div>
                </div>

                <div class="checkbox-register-wrapper">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="registerAsCompany"
                        name="checkbox-1"
                        :value="true"
                        :unchecked-value="false"
                    >
                        {{ $t('registerAsCompany') }}
                    </b-form-checkbox>
                </div>

                <div v-if="registerAsCompany">

                    <div class="flex-row flex-row--3">
                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_name') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_name"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_name.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_name.required">{{ $t('vForm.company_name.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_name && errors.company_name[0]">
                                <div class="error-text">{{ errors.company_name[0] }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row flex-row--3">
                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_business_id') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_business_id"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_business_id.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_business_id.required">{{ $t('vForm.company_business_id.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_business_id && errors.company_business_id[0]">
                                <div class="error-text">{{ errors.company_business_id[0] }}</div>
                            </div>
                        </div>

                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_tax_id') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_tax_id"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_tax_id.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_tax_id.required">{{ $t('vForm.company_tax_id.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_tax_id && errors.company_tax_id[0]">
                                <div class="error-text">{{ errors.company_tax_id[0] }}</div>
                            </div>
                        </div>

                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_vat_id') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_vat_id"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_vat_id && errors.company_vat_id[0]">
                                <div class="error-text">{{ errors.company_vat_id[0] }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row flex-row--4">
                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_city') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_city"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_city.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_city.required">{{ $t('vForm.company_city.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_city && errors.company_city[0]">
                                <div class="error-text">{{ errors.company_city[0] }}</div>
                            </div>
                        </div>

                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_street') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_street"
                                    type="text"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_street.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_street.required">{{ $t('vForm.company_street.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_street && errors.company_street[0]">
                                <div class="error-text">{{ errors.company_street[0] }}</div>
                            </div>
                        </div>

                        <div class="input-container">
                            <div class="input-wrapper">
                                <div class="input-label">{{ $t('company_zip_code') }}:</div>
                                <b-form-input
                                    class="input"
                                    v-model="formDataCompany.company_zip_code"
                                    type="number"
                                ></b-form-input>
                            </div>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_zip_code.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_zip_code.required">{{ $t('vForm.company_zip_code.required') }}</div>
                                <div class="error-text" v-if="!$v.formDataCompany.company_zip_code.minLength || !$v.formDataCompany.company_zip_code.maxLength">{{ $t('vForm.company_zip_code.minLength') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_zip_code && errors.company_zip_code[0]">
                                <div class="error-text">{{ errors.company_zip_code[0] }}</div>
                            </div>
                        </div>

                        <div class="select-country-wrapper">
                            <b-form-select
                                placeholder="test"
                                v-model="formDataCompany.company_country_id"
                                :options="countriesOptions"
                            ></b-form-select>
                            <div class="error-text-wrapper" v-if="$v.formDataCompany.company_country_id.$error">
                                <div class="error-text" v-if="!$v.formDataCompany.company_country_id.required">{{ $t('vForm.company_country_id.required') }}</div>
                            </div>
                            <div class="error-text-wrapper" v-if="errors.company_country_id && errors.company_country_id[0]">
                                <div class="error-text">{{ errors.company_country_id[0] }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="consents-wrapper">
                    <div class="checkbox-register-wrapper">
                        <b-form-checkbox
                            id="checkbox-2"
                            v-model="formData.gdpr_accept"
                            name="checkbox-2"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ $t('agreeWith') }}
                            <span class="conditions-link" @click="openConditions('gdpr_page')">{{ $t('gdpr_accept') }}</span>
                        </b-form-checkbox>
                        <div class="error-text-wrapper" v-if="$v.formData.gdpr_accept.$error">
                            <div class="error-text" v-if="!$v.formData.gdpr_accept.sameAs">{{ $t('vForm.gdpr_accept.sameAs') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.gdpr_accept && errors.gdpr_accept[0]">
                            <div class="error-text">{{ errors.gdpr_accept[0] }}</div>
                        </div>
                    </div>

                    <div class="checkbox-register-wrapper">
                        <b-form-checkbox
                            id="checkbox-3"
                            v-model="formData.privacy_policy_customer"
                            name="checkbox-3"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ $t('agreeWith') }}
                            <span class="conditions-link" @click="openConditions('privacy_policy_page')">{{ $t('privacy_policy_customer') }}</span>
                        </b-form-checkbox>
                        <div class="error-text-wrapper" v-if="$v.formData.privacy_policy_customer.$error">
                            <div class="error-text" v-if="!$v.formData.privacy_policy_customer.sameAs">{{ $t('vForm.privacy_policy_customer.sameAs') }}</div>
                        </div>
                        <div class="error-text-wrapper" v-if="errors.privacy_policy_customer && errors.privacy_policy_customer[0]">
                            <div class="error-text">{{ errors.privacy_policy_customer[0] }}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <b-button
                        class="button"
                        @click="postData"
                        :disabled="loaders.postData"
                    >
                        <b-spinner v-if="loaders.postData" label="Spinning"></b-spinner>
                        <span v-else>{{ $t('signIn') }}</span>
                    </b-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { required, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input';
import Banner from "../components/homepage/Banner.vue";

export default {
    name: "PageRegistration",
    components: {
        Banner,
        VueTelInput,
    },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                email: '',

                phone: '',
                password: '',
                password_confirmation: '',

                street: '',
                zip_code: '',
                city: '',
                country_id: null,

                gdpr_accept: false,
                privacy_policy_customer: false,
            },
            registerAsCompany: false,
            formDataCompany: {
                company_name: '',

                company_business_id: '',
                company_tax_id: '',
                company_vat_id: '',

                company_city: '',
                company_street: '',
                company_zip_code: '',
                company_country_id: null
            },
            countriesOptions: [],

            errors: {},

            loaders: {
                postData: false
            },
            settings: {}
        }
    },
    mounted() {
        this.getSettings()
        this.getCountries()
    },
    methods: {
        getSettings() {
            this.$axios.get(this.$config.API_URL_V1 + 'settings', this.$config.HEADERS)
                .then(res => {
                    // console.log('axiosGetDefaultAppData', res)
                    this.settings = res.data
                }, (error) => {
                    console.warn(error.response.data)
                })
        },
        resetData() {
            this.formData = {
                first_name: '',
                last_name: '',
                email: '',

                phone: '',
                password: '',
                password_confirmation: '',

                street: '',
                zip_code: '',
                city: '',
                country_id: null,

                gdpr_accept: false,
                privacy_policy_customer: false,
            }
            this.registerAsCompany = false
            this.formDataCompany = {
                company_name: '',
                company_business_id: '',
                company_tax_id: '',
                company_vat_id: '',
                company_city: '',
                company_street: '',
                company_zip_code: '',
                company_country_id: null
            }
            this.errors = {}
        },
        getCountries() {
            this.$axios.get(this.$config.API_URL_V1 + 'countries', this.$config.HEADERS).then(res => {
                // console.log('getCountries', res)
                let options = res.data.data.map(country => {
                    return {
                        value: country.id,
                        text: country.title,
                    }
                })
                options.unshift({ value: null, text: this.$t('country_id') })
                this.countriesOptions = options
            })
        },
        openConditions(type) {
            if (this.settings[type]) window.open(this.settings[type], "_blank")
        },
        postData() {
            if (this.registerAsCompany) {
                this.$v.$touch()
                if (this.$v.$invalid) return false
            } else {
                this.$v.formData.$touch()
                if (this.$v.formData.$invalid) return false
            }

            this.loaders.postData = true

            let postData = new FormData()
            Object.keys(this.formData).forEach(key => {
                postData.set(key, this.formData[key])
            })
            postData.set('gdpr_accept', this.formData.gdpr_accept ? 1 : 0)
            postData.set('privacy_policy_customer', this.formData.privacy_policy_customer ? 1 : 0)

            postData.set('company', this.registerAsCompany ? 1 : 0)
            if (this.registerAsCompany) {
                Object.keys(this.formDataCompany).forEach(key => {
                    postData.set(key, this.formDataCompany[key])
                })
            }

            postData.set('app_version', 'web')
            postData.set('app_lang', this.$i18n.locale)
            postData.set('device_token', null)
            postData.set('role', 'customer')
            postData.set('platform', 'other')

            this.$axios.post(this.$config.API_URL_V1 + 'register', postData, this.$config.HEADERS).then(() => {
                // console.log('postData', res)
                this.$v.$reset()
                this.resetData()
                window.open(this.$config.BASE_URL + '/login', "_blank")
            }, e => {
                // console.log('error', e)
                this.errors = e.response.data.errors
            }).finally(() => {
                this.loaders.postData = false
            })
        }
    },
    computed: {
        //
    },
    validations: {
        formData: {
            first_name: {
                required
            },
            last_name: {
                required
            },
            email: {
                required,
                email
            },
            phone: {
                required,
                minLength: minLength(10)
            },
            password: {
                required,
                minLength: minLength(8)
            },
            password_confirmation: {
                required,
                minLength: minLength(8),
                sameAs: sameAs('password')
            },
            gdpr_accept: {
                sameAs: sameAs(() => true)
            },
            privacy_policy_customer: {
                sameAs: sameAs(() => true)
            },
            street: {
                required,
            },
            zip_code: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
            city: {
                required,
            },
            country_id: {
                required,
            }
        },

        formDataCompany: {
            company_name: {
                required
            },
            company_business_id: {
                required
            },
            company_tax_id: {
                required
            },
            company_city: {
                required
            },
            company_street: {
                required
            },
            company_zip_code: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
            company_country_id: {
                required
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-registration {
    .component-banner {
        max-height: 200px !important;
    }

    .registration-title {
        font-size: 25px;
        margin-top: 50px;
        text-align: center;
    }

    .container {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .registration-form {

        .flex-row {
            margin: 30px 0;

            &--3 {
                display: flex;
                gap: 50px;

                > div {
                    width: 100%;
                }
            }

            &--4 {
                display: flex;
                gap: 30px;

                > div {
                    width: 100%;
                }
            }
        }
    }

    .input-container {
        .input-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            border-bottom: 1px solid $primary;

            .input-label {
                white-space: nowrap;
            }

            .input {
                padding: 0;
                border: 0;
                border-radius: 0;
                height: auto;

                &:focus {
                    outline: 0;
                    box-shadow: none;
                }

                &::-webkit-input-placeholder {
                    font-size: 14px;
                }
            }
        }
    }

    .vue-tel-input {
        min-height: auto;
        border: 0 !important;

        &:focus-visible {
            outline: 0;
        }

        .vti__input {
            width: 100%;
            border: 0 !important;
        }
    }

    .checkbox-register-wrapper {
        margin: 20px 0;
    }

    .conditions-link {
        color: $primary;
        text-decoration: underline;
    }

    .error-text-wrapper {
        font-size: 12px;
        color: red;
        //position: absolute;
    }

    .select-country-wrapper {
        .custom-select {
            height: auto;
            padding: 0;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $primary;

            &:focus,
            &:focus-visible {
                outline: 0;
                box-shadow: none,
            }
        }
    }

    .button {
        display: flex;
        justify-content: center;

        border: 0;
        outline: 0;
        background: $primary;

        &:active,
        &:focus,
        &:focus-visible {
            outline: 0;
            box-shadow: none;
        }
    }

    @media (max-width: 992px) {
        .flex-row {
            margin: 20px 0 !important;

            &--3 {
                gap: 20px !important;
                flex-direction: column !important;
            }

            &--4 {
                gap: 20px !important;
                flex-direction: column !important;
            }
        }
    }
}
</style>
