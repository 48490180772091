<template>
	<div class="component-the-benefits">
		<div class="container">
			<h2 class="title">{{ $t('transferServiceAdvantages.title') }}</h2>

			<div class="benefits-list">
				<div
                    v-for="(item, index) in benefits" :key="index"
                    class="benefit-item"
                >
					<div class="icon-wrapper">
						<img class="icon" :src="item.icon" alt="icon">
					</div>
					<div class="text">{{ $t(item.tKey) }}</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import wifi from "../../assets/icons/wifi.png"
import list from "../../assets/icons/vypisjazdicon.png"
import order from "../../assets/icons/onlineobjednavkyicon.png"
import driver from "../../assets/icons/vyskolenisoferiicon.png"
import car from "../../assets/icons/miestovyzdvihnutiaicon.png"
import guarantee from "../../assets/icons/guarantee.png"
import bell from "../../assets/icons/bell-solid.png"
import clock from "../../assets/icons/clock-solid.png"
import card from "../../assets/icons/credit-card-regular.png"
import phone from "../../assets/icons/24-hours-phone-service.png"
import utensils from "../../assets/icons/utensils-solid.png"
import currency from "../../assets/icons/transparentnecenyicon.png"

export default {
	data() {
		return {
			benefits: [
				{ icon: wifi, tKey: 'transferServiceAdvantages.wifi' },
				{ icon: list, tKey: 'transferServiceAdvantages.list' },
				{ icon: order, tKey: 'transferServiceAdvantages.order1' },
				{ icon: driver, tKey: 'transferServiceAdvantages.driver' },
				{ icon: car, tKey: 'transferServiceAdvantages.car1' },
				{ icon: guarantee, tKey: 'transferServiceAdvantages.guarantee' },
                { icon: currency, tKey: 'transferServiceAdvantages.currency1' },
                { icon: utensils, tKey: 'transferServiceAdvantages.utensils' },
                { icon: phone, tKey: 'transferServiceAdvantages.phone' },
                { icon: card, tKey: 'transferServiceAdvantages.card' },
                { icon: clock, tKey: 'transferServiceAdvantages.clock' },
                { icon: currency, tKey: 'transferServiceAdvantages.currency2' },
                { icon: bell, tKey: 'transferServiceAdvantages.bell' },
                { icon: car, tKey: 'transferServiceAdvantages.car2' },
                { icon: order, tKey: 'transferServiceAdvantages.order2' },
			]
		}
	},
}
</script>

<style lang="scss" scoped>
.component-the-benefits::v-deep {
	background: $bg-dark;
	padding: 50px 0 30px;

	.title {
		color: white;
		text-align: center;
		
		&:before, &:after {
			content: "+";
			color: $primary;
		}
		
		&:before {
			margin-right: 30px;
		}
		
		&:after {
			margin-left: 30px;
		}

        @media (max-width: 992px) {
            &:before, &:after {
                display: none;
            }
        }
	}
	
	.benefits-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		
		.benefit-item {
			display: flex;
			flex-direction: row;
			flex-basis: calc(100% / 3);
			align-items: center;
            margin: 10px 0;

			.icon-wrapper {
				flex-basis: 30%;
                display: flex;
                justify-content: center;
                align-items: center;

				.icon {
					margin: 0 auto;
					width: 40px;
					height: 100%;
					object-fit: contain;
				}
			}

			.text {
				color: white;
				font-size: 14px;
				flex-basis: 70%;
                //text-align: center;
                margin-top: 15px;
			}
		}

        @media (max-width: 992px) {
            .benefit-item {
                flex-basis: calc(100% / 2);
            }
        }

        @media (max-width: 768px) {
            .benefit-item {
                flex-basis: 100%;
            }
        }
	}
}
</style>