export default {
    // DEV
    BASE_URL: "https://carsen.wame.sk",
    API_URL_V1: "https://carsen.wame.sk/api/v1/",

    HEADERS: {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    },
    FD_HEADERS: {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        }
    }
}