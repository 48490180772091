<template>
	<div class="component-home-tabs">
        <div class="panel-tabs-wrapper">
            <div
                class="panel-tab"
                v-for="item in panelTabs" :key="item.id"
                :class="{ 'panel-tab-active': panelIsActive(item.id) }"
                @click="selectPanelTab(item.id)"
            >
                {{ $t(item.tKey) }}
            </div>
        </div>
        <div class="selected-content-wrapper">
            <div v-show="panelIsActive(0)" class="selected-content-order">
                <div id="carsen-order-form-container">
                    <div id="carsen-order-form"></div>
                </div>
				
				<b-modal ref="formModal" :centered="true" :cancel-title="modalCancelText" :ok-title="modalOkText" @ok="modalSubmit" @cancel="modalCancel">{{ modalTitle }}</b-modal>
            </div>
            <div v-show="panelIsActive(2)" class="selected-content-cars">
                <CarPark />
            </div>
            <div v-show="panelIsActive(3)" class="selected-content-benefits">
                <h4 class="title">{{ $t('carPark.title') }}</h4>

                <div class="benefits-list">
                    <div
                        v-for="(item, index) in benefits" :key="index"
                        class="benefit-item"
                    >
                        <div class="icon-wrapper">
                            <img class="icon" :src="item.icon" alt="icon">
                        </div>
                        <div class="text">{{ $t(item.tKey) }}</div>
                    </div>
                </div>
            </div>
        </div>

	</div>
</template>

<script>
import wifi from "../../assets/icons/wifi.png";
import list from "../../assets/icons/vypisjazdicon.png";
import order from "../../assets/icons/onlineobjednavkyicon.png";
import driver from "../../assets/icons/vyskolenisoferiicon.png";
import currency from "../../assets/icons/transparentnecenyicon.png";
import card from "../../assets/icons/credit-card-regular.png";
import CarPark from "./elements/CarPark.vue";

export default {
    components: {CarPark},
    data() {
        return {
            panelTabs: [
                { id: 0, tKey: 'homeTabs.order' },
                { id: 2, tKey: 'homeTabs.carRental' },
                { id: 3, tKey: 'homeTabs.benefits' },
            ],
            currentTab: 0,
            benefits: [
                { icon: wifi, tKey: 'benefits.wifi' },
                { icon: driver, tKey: 'benefits.driver' },
                { icon: currency, tKey: 'benefits.currency' },
                { icon: list, tKey: 'benefits.list' },
                { icon: card, tKey:  'benefits.card' },
                { icon: order, tKey: 'benefits.order' }
            ],
	
			modalTitle: '',
			modalCancelText: '',
			modalOkText: ''
        }
    },
    mounted() {
        this.appendScript()
    },
    methods: {
        appendScript() {
			let self = this
			
            // import external component for create order
            const script = document.createElement('script')

            // localhost
            // script.src = 'http://localhost:8080/carsenForm.js'
            // script.src = "https://form.carsen.dev.wame.sk/carsenFormjs"

            // prod
            // const scriptName = "carsenForm.js?v=2"
            // script.src = "https://form.carsen.wame.sk/" + scriptName
            script.src = "https://form.carsen.wame.sk/carsenForm.js"

            // dev
            // const scriptName = "carsenForm-1673456437670.js"
            // script.src = "https://form.carsen.dev.wame.sk/" + scriptName
            // script.src = "https://form.carsen.dev.wame.sk/carsenForm.js"

            script.type = "text/javascript"
			// script.onload = function () {self.initResizer()}
            document.querySelector('#carsen-order-form-container').appendChild(script);
			
			window.addEventListener('message', function (e) {
				if (e.data.type === 'modal') {
					console.log('modal received', e.data)
					
					// set modal variables
					self.modalTitle = e.data.title
					self.modalCancelText = e.data.cancelText
					self.modalOkText = e.data.okText
					// open modal
					self.$refs.formModal.show()
				}
			})
        },
		modalSubmit() {
			// ok modal callback
			window.postMessage({
				type: 'modal-response'
			}, '*')
		},
		modalCancel() {
			// cancel modal callback
			window.postMessage({
				type: 'modal-reject'
			}, '*')
		},
        selectPanelTab(panelTabId) {
            this.currentTab = panelTabId
        },
		initResizer() {
			console.log('init resizer')
			const resizeObserver = new ResizeObserver((entries) => {
				console.log(entries)

				const app = document.querySelector('#carsen-order-form ion-app')
				console.log(app)
				app.style.height = entries[0].contentRect.height + 'px'
				app.style.width = '100%'
			})

			resizeObserver.observe(document.querySelector('#carsen-order-form ion-content > div'))
		}
    },
    computed: {
        panelIsActive() {
            return panelTabId => panelTabId === this.currentTab
        }
    }
}
</script>

<style lang="scss" scoped>
.component-home-tabs::v-deep {
    position: relative;
    margin-top: -50px;
    margin-bottom: 20px;
    background: $ion-color-gray-content;
    z-index: 2;

    .panel-tabs-wrapper {
        display: flex;
        flex-direction: row;
        min-height: 50px;

        .panel-tab {
            flex: 0 1 calc(100% / 3);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 3px solid $primary;
            background: white;

            &:hover {
                background: $ion-color-gray-content;
                font-weight: 600;
            }

            &.panel-tab-active {
                background: $ion-color-gray-content;
                font-weight: 600;
                border-bottom: 0;
            }
        }
    }

    .selected-content-wrapper {
        //padding: 20px;
        background: white;
        //background: $ion-color-gray-content;

        [class*='selected-content-'] {
            background: white;
            margin-bottom: 30px;
        }
	
		.selected-content-order,
		.selected-content-price,
		.selected-content-cars,
		.selected-content-benefits {
			padding: 20px;
			
			@media (max-width: 992px) {
				padding: 20px 0;
			}
		}
    }

    .selected-content-order {
        //background: $ion-color-gray-content;
    }

    .selected-content-benefits {
        h4 {
            margin-top: 20px;
            margin-bottom: 25px;
            text-align: center;
        }

        .benefits-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .benefit-item {
                flex: 0 1 calc(100% / 3);

                display: flex;
                flex-direction: column;
                //justify-content: center;
                margin: 20px 0;
                padding: 0 20px;

                .icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                }

                .text {
                    font-size: 14px;
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .selected-content-benefits {
            .benefits-list {
                .benefit-item {
                    flex: 0 1 calc(100% / 2);
                }
            }
        }
    }

    @media (max-width: 768px) {
        margin: 0;

        .panel-tabs-wrapper {
            flex-wrap: wrap;
            height: 70px;
            border-bottom: 3px solid $primary;

            .panel-tab {
                flex: 0 1 calc(100% / 2);
                border: 0;
            }
        }

        .selected-content-benefits {
            .benefits-list {
                .benefit-item {
                    flex: 0 1 100%;
                }
            }
        }
    }
}
</style>