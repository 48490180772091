<template>
    <footer class="component-the-footer">
        <div class="container">
            <b-row class="contact-section-wrapper">
                <b-col cols="12" md="6" class="contact-section contact-section--left">
                    <h2 class="footer-title">{{ $t(menuItems.contact) }}</h2>
                    <div class="footer-contact">
                        <div class="contact-item">
                            {{ $t('footer.phone') }}:
                            <a href="tel:+421944554555">
                                <span class="highlight">{{ getAppSettingsByKey('hotline_phone') }} (nonstop)</span>
                            </a>
                        </div>
                        <div class="contact-item">
                            Email:
                            <a href="mailto:info@transferservice.sk">
                                <span class="highlight">{{ getAppSettingsByKey('company_email') }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="footer-desc">
                        <p>{{ $t('footer.desc1') }}</p>
                        <p>{{ $t('footer.desc2') }}</p>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="contact-section contact-section--right">
                    <div class="footer-company footer-company--info">
                        <p class="text">{{ getAppSettingsByKey('trips_base_location') }}</p>
                        <p class="text">{{ $t('footer.businessId') }}: 45420360</p>
                        <p class="text">{{ $t('footer.vatId') }}: 2023013487</p>
                        <p class="text">{{ $t('footer.taxId') }}: SK2023013487</p>
                    </div>

                    <div class="footer-company footer-company--bank">
                        <p class="text">ČSOB Banka: 4020855960/7500</p>
                        <p class="text">IBAN/SWIFT: SK 71 7500 0000 0040 2085 5960/CEKOSKBX</p>
                    </div>
                </b-col>
            </b-row>

            <div class="footer-menu-wrapper">
                <div class="footer-menu">
                    <div class="menu-item" v-for="(item, index) in menuItems" :key="index">
                        <a href="#">{{ $t(item.tKey) }}</a>
                    </div>
                </div>

                <div class="footer-menu">
                    <div class="menu-item" v-for="(item, index) in conditionsItems" :key="index">
                        <a href="#">{{ $t(item.tKey) }}</a>
                    </div>
                </div>
            </div>

            <div class="copyright-wrapper">
                <p class="copyright">Copyright {{ dateYear }} transferservice.sk</p>
                <p class="copyright">
                    Created by
                    <a class="wame-link highlight" href="https://wame.sk/e-shopove-riesenie-wame-admin-cms" target="_blank">wame.sk</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            menuItems: [
                { tKey: 'menuItems.introduction', redirect: '' },
                { tKey: 'menuItems.services', redirect: '' },
                { tKey: 'menuItems.aboutUs', redirect: 'PageAboutUs' },
                { tKey: 'menuItems.contact', redirect: '' },
                { tKey: 'menuItems.career', redirect: '' },
                { tKey: 'menuItems.carRental', redirect: '' },
            ],
            conditionsItems: [
                { tKey: 'menuItems.generalTermsConditions', redirect: '' },
                { tKey: 'menuItems.transportationSchedule', redirect: '' },
            ],
            dateYear: new Date().getFullYear(),
        }
    },
    computed: {
        ...mapGetters({
            getAppSettingsByKey: 'getAppSettingsByKey'
        })
    },
}
</script>

<style lang="scss" scoped>
.component-the-footer::v-deep {
    background: $bg-dark;
    padding: 50px 0 30px;

    .contact-section-wrapper {
        .contact-section {
            &--left, &--right {
                display: flex;
                flex-direction: column;
            }

            &--left {
                justify-content: space-between;

                .footer-title {
                    color: white;
                }

                .footer-contact {
                    color: white;

                    a {
                        color: white;
                    }
                }

                .footer-desc {
                    margin-top: 20px;
                    font-size: 14px;
                    color: $heading-gray;
                }
            }

            &--right {
                justify-content: flex-end;

                .footer-company {
                    color: $heading-gray;

                    &--info {
                        //
                    }

                    &--bank {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .footer-menu-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 20px;

        .footer-menu {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 10px;

            .menu-item {
                margin: 0 10px 0 10px;
                text-align: center;

                a {
                    color: white;
                }
            }
        }
    }

    .copyright-wrapper {
        color: $heading-gray;
        text-align: center;

        .copyright {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .wame-link {
                color: white;
            }
        }
    }
}
</style>