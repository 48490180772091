<template>
    <div id="transfer_service_web">
        <MainMenu />

        <router-view/>

        <TheFooter />
    </div>
</template>

<script>
import MainMenu from "./components/general/MainMenu";
import TheFooter from "./components/general/TheFooter";
import {mapMutations} from "vuex";

export default {
    components: {
        MainMenu,
        TheFooter
    },
    created() {
        this.getAppSettings()
    },
    methods: {
        ...mapMutations({
            setAppSettings: 'setAppSettings'
        }),
        getAppSettings() {
            this.$axios.get(this.$config.API_URL_V1 + 'settings', this.$config.HEADERS)
                .then(res => {
                    // console.log('axiosGetDefaultAppData', res)
                    this.setAppSettings(res.data)
                }, (error) => {
                    console.warn(error.response.data)
                })
        }
    }
}
</script>

<style lang="scss">
    //
</style>