<template>
    <div class="component-never-pay">
        <div class="container">
            <h2 class="title">{{ $t('neverPay.title') }}</h2>
            <b-row>
                <b-col cols="12" lg="6" class="never-pay-info-wrapper">
                    <p class="never-pay-info">{{ $t('neverPay.infoText') }}</p>
                </b-col>
                <b-col cols="12" lg="6" class="never-pay-items-wrapper">

                    <b-row>
                        <b-col cols="12" md="6" class="never-pay-items never-pay-items--left">
                            <NeverPayItem
                                v-for="index in featuresLeft" :key="index"
                                :number="'0' + index + '.'"
                                :text="$t('neverPay.text' + index)"
                            />
                        </b-col>
                        <b-col cols="12" md="6" class="never-pay-items never-pay-items--right">
                            <NeverPayItem
                                v-for="index in featuresRight" :key="index"
                                :number="'0' + (index + 3) + '.'"
                                :text="$t('neverPay.text' + index)"
                            />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import NeverPayItem from "./NeverPayItem";

export default {
    components: {
        NeverPayItem
    },
    data() {
        return {
            featuresLeft: 3,
            featuresRight: 3
        }
    },
}
</script>

<style lang="scss" scoped>
.component-never-pay::v-deep {
    padding: 50px 0;

    .title {
        margin-bottom: 30px;
    }

    .never-pay-info-wrapper {
        .never-pay-info {
            color: $heading-gray;
        }
    }

    .never-pay-items-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;

        .never-pay-items {
            white-space: nowrap;
        }
    }
}
</style>