import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./theme/fonts.scss"

/**
 * Import Boostrap
 * Import Boostrap Icons
 */
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './theme/app.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import langTranslations from './lang/index'
const i18n = new VueI18n({
    locale: 'sk',
    messages: langTranslations,
})

import axios from 'axios';
Vue.prototype.$axios = axios;

import config from "./config";
Vue.prototype.$config = config;

/**
 * Production mode
 * @type {boolean}
 */
Vue.config.productionTip = false

/**
 * Vue app init
 */
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#transfer_service_web')
