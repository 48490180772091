<template>
	<b-row class="component-tabs-benefits">
        <b-col
            class="benefit-item"
            cols="12" lg="4"
            v-for="(item, index) in benefitItems" :key="index"
        >
            <div class="benefit-item-label">
                <div class="icon-wrapper">
                    <img class="icon" :src="item.icon" alt="benefit icon">
                </div>
                <div class="title">{{ $t(item.tKey.label) }}</div>
            </div>
            <div class="benefit-item-desc">{{ $t(item.tKey.text) }}</div>
        </b-col>
	</b-row>
</template>

<script>
import driver from "../../assets/icons/vyskolenisoferi.svg"
import noFee from "../../assets/icons/ziadnepoplatky.svg"
import neverPay from "../../assets/icons/nikdyneplatite.svg"

export default {

    data() {
        return {
            benefitItems: [
                { icon: driver, tKey: { label: 'tabBenefits.driver.label', text: 'tabBenefits.driver.text' }},
                { icon: noFee, tKey: { label: 'tabBenefits.noFee.label', text: 'tabBenefits.noFee.text' }},
                { icon: neverPay, tKey: { label: 'tabBenefits.neverPay.label', text: 'tabBenefits.neverPay.text' }}
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.component-tabs-benefits::v-deep {
	.benefit-item {
        .benefit-item-label {
            display: flex;
            align-items: center;
            justify-content: center;

            .title {
                margin-left: 10px;
                color: $heading-gray;
                font-weight: 600;
            }
        }

        .benefit-item-desc {
            text-align: center;
            margin-top: 5px;
        }
    }

    @media (max-width: 992px) {
        .benefit-item {
            margin: 20px 0;
        }
    }
}
</style>